<template>
  <nav class="navigation-links-nav" v-bind:class="rootClassName">
    <span class="navigation-links-text">{{ text }}</span>
    <span class="navigation-links-text1">{{ text1 }}</span>
    <span class="navigation-links-text2">{{ text2 }}</span>
    <span class="navigation-links-text3">{{ text3 }}</span>
  </nav>
</template>

<script>
export default {
  name: 'NavigationLinks',
  props: {
    text1: {
      type: String,
      default: 'Features',
    },
    text2: {
      type: String,
      default: 'Pricing',
    },
    text3: {
      type: String,
      default: 'Team',
    },
    rootClassName: String,
    text: {
      type: String,
      default: 'About',
    },
    text4: {
      type: String,
      default: 'Blog',
    },
  },
}
</script>

<style scoped>
.navigation-links-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.navigation-links-text {
  color: #ffffff;
}
.navigation-links-text1 {
  color: #ffffff;
  margin-left: var(--dl-space-space-twounits);
}
.navigation-links-text2 {
  color: #ffffff;
  margin-left: var(--dl-space-space-twounits);
}
.navigation-links-text3 {
  color: #ffffff;
  margin-left: var(--dl-space-space-twounits);
}










@media(max-width: 767px) {
  .navigation-links-nav {
    gap: var(--dl-space-space-twounits);
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
  }
  .navigation-links-text {
    margin-bottom: var(--dl-space-space-unit);
  }
  .navigation-links-text1 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .navigation-links-text2 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .navigation-links-text3 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .navigation-links-nav {
    justify-content: center;
  }
}
</style>
