<template>
  <div class="button-black-frame" v-bind:class="rootClassName">
    <span class="button-black-text Button1">{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: 'ButtonBlack',
  props: {
    text: {
      type: String,
      default: 'Click Me!',
    },
    rootClassName: String,
  },
}
</script>

<style scoped>
.button-black-frame {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-fourunits);
  border-radius: var(--dl-radius-radius-radiusbutton);
  padding-right: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: #000000;
}
.button-black-text {
  color: #ffffff;
  width: auto;
  height: auto;
  align-self: center;
  text-align: center;
  font-stretch: normal;
}





@media(max-width: 1200px) {
  .button-black-frame {
    position: static;
    flex-direction: row;
    justify-content: flex-start;
  }
  .button-black-text {
    position: static;
  }
}
@media(max-width: 479px) {
  .button-black-frame {
    width: 80%;
    justify-content: center;
  }
  .button-black-root-class-name {
    position: 0px;
  }
  .button-black-root-class-name1 {
    position: 0px;
  }
  .button-black-root-class-name3 {
    position: 0px;
  }
  .button-black-root-class-name4 {
    position: 0px;
  }
  .button-black-root-class-name5 {
    position: 0px;
  }
}
</style>
