<template>
  <div class="app-component-container" v-bind:class="rootClassName">
    <div class="app-component-container1">
      <img :alt="vectorAlt" :src="vectorSrc" class="app-component-vector" />
      <animate-on-reveal
        animation="none"
        duration="300ms"
        delay="0s"
        direction="normal"
        easing="ease"
        iteration="1"
      >
        <img
          :alt="vectorAlt1"
          :src="vectorSrc1"
          data-thq-animate-on-reveal="true"
          class="app-component-vector1"
        />
      </animate-on-reveal>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppComponent',
  props: {
    vectorAlt1: {
      type: String,
      default: 'Vector4111',
    },
    vectorAlt: {
      type: String,
      default: 'Vector4110',
    },
    vectorSrc: {
      type: String,
      default: '/external/vector4110-af5.svg',
    },
    vectorSrc1: {
      type: String,
      default: '/external/vector4111-sftl.svg',
    },
    rootClassName: String,
  },
}
</script>

<style scoped>
.app-component-container {
  width: 657px;
  height: 479.328125px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.app-component-container1 {
  width: 657px;
  height: 479.3359375px;
  display: flex;
  align-items: flex-start;
}
.app-component-vector {
  left: 279.359375px;
  width: 288px;
  bottom: 0px;
  height: 110px;
  position: absolute;
}
.app-component-vector1 {
  top: 0px;
  left: 0px;
  width: 657px;
  height: 479px;
  position: absolute;
}
.app-component-root-class-name {
  right: var(--dl-space-space-threeunits);
  bottom: var(--dl-space-space-threeunits);
  position: absolute;
}
@media(max-width: 1200px) {
  .app-component-container {
    align-items: center;
    justify-content: center;
  }
  .app-component-root-class-name {
    right: var(--dl-space-space-threeunits);
    bottom: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 991px) {
  .app-component-vector {
    left: 279.359375px;
    bottom: 0px;
  }
  .app-component-vector1 {
    top: 0px;
    left: 0px;
  }
  .app-component-root-class-name {
    right: var(--dl-space-space-threeunits);
    bottom: var(--dl-space-space-threeunits);
    position: absolute;
  }
}
</style>
