<template>
  <div class="service-card-feature-card" v-bind:class="rootClassName">
    <slot></slot>
    <h2 class="service-card-text Heading3">{{ title }}</h2>
    <span class="service-card-text1 Content2">{{ description }}</span>
  </div>
</template>

<script>
export default {
  name: 'ServiceCard',
  props: {
    description: {
      type: String,
      default:
        'Build robust platforms and intuitive applications tailored to your needs, enhancing efficiency and user experience',
    },
    title: {
      type: String,
      default: 'Platform and App Creation',
    },
    rootClassName: String,
  },
}
</script>

<style scoped>
.service-card-feature-card {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.service-card-text {
  color: #ffffff;
  align-self: center;
  text-align: center;
}
.service-card-text1 {
  color: #ffffff;
  align-self: center;
  text-align: center;
}












</style>
