<template>
  <header class="header-frame" v-bind:class="rootClassName">
    <router-link to="/" class="header-navlink">
      <div>
        <div class="header-group">
          <img :alt="vectoralt" :src="vectorsrc" class="header-vector" />
          <img :alt="vectoralt1" :src="vectorsrc1" class="header-vector1" />
        </div>
        <span class="header-text">Astronod</span>
      </div>
    </router-link>
    <div class="header-frame1">
      <a href="#services" class="header-text01"><span>Our Services</span></a>
      <a href="#contact" class="header-text03"><span>Contact</span></a>
      <router-link to="/company" class="header-text05">
        <span>Company</span>
      </router-link>
      <span class="header-text07"><span>Our Work</span></span>
    </div>
    <div data-thq="thq-dropdown" class="header-thq-dropdown list-item">
      <div data-thq="thq-dropdown-toggle" class="header-dropdown-toggle">
        <span class="header-text09">{{ text }}</span>
        <div data-thq="thq-dropdown-arrow" class="header-dropdown-arrow">
          <svg viewBox="0 0 1024 1024" class="header-icon">
            <path d="M426 726v-428l214 214z"></path>
          </svg>
        </div>
      </div>
      <ul data-thq="thq-dropdown-list" class="header-dropdown-list">
        <li data-thq="thq-dropdown" class="header-dropdown list-item">
          <div data-thq="thq-dropdown-toggle" class="header-dropdown-toggle1">
            <span class="header-text10">{{ text1 }}</span>
          </div>
        </li>
        <li data-thq="thq-dropdown" class="header-dropdown1 list-item">
          <div data-thq="thq-dropdown-toggle" class="header-dropdown-toggle2">
            <span class="header-text11">{{ text2 }}</span>
          </div>
        </li>
        <li data-thq="thq-dropdown" class="header-dropdown2 list-item">
          <div data-thq="thq-dropdown-toggle" class="header-dropdown-toggle3">
            <span class="header-text12">{{ text3 }}</span>
          </div>
        </li>
      </ul>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  props: {
    text2: {
      type: String,
      default: 'Sub-menu Item',
    },
    vectorsrc: {
      type: String,
      default: '/external/vector4112-brh.svg',
    },
    vectoralt: {
      type: String,
      default: 'Vector4112',
    },
    vectorsrc1: {
      type: String,
      default: '/external/vector4112-bqb.svg',
    },
    text1: {
      type: String,
      default: 'Sub-menu Item',
    },
    vectoralt1: {
      type: String,
      default: 'Vector4112',
    },
    text: {
      type: String,
      default: 'Menu Item',
    },
    text3: {
      type: String,
      default: 'Sub-menu Item',
    },
    rootClassName: String,
  },
}
</script>

<style scoped>
.header-frame {
  width: 100%;
  display: flex;
  z-index: 1;
  position: fixed;
  max-width: 1200px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
  background-color: #000000;
}
.header-navlink {
  display: contents;
}
.header-frame1 {
  gap: 10px;
  display: flex;
  align-items: flex-start;
  text-decoration: none;
}
.header-group {
  width: 70px;
  height: 43px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
.header-vector {
  top: 34.91851806640625px;
  left: 14.66930103302002px;
  width: 41px;
  height: 8px;
  position: absolute;
}
.header-vector1 {
  top: 0px;
  left: 0px;
  width: 70px;
  height: 43px;
  position: absolute;
}
.header-text {
  color: rgb(255, 255, 255);
  width: 156px;
  height: auto;
  font-size: 30px;
  font-style: Regular;
  text-align: left;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 42.56px;
  font-stretch: normal;
  text-decoration: none;
}
.header-frame1 {
  width: 401px;
  height: 31.984375px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
.header-text01 {
  top: 0.859375px;
  left: 100px;
  color: rgba(255, 255, 255, 1);
  width: 96px;
  height: auto;
  position: absolute;
  font-size: 15px;
  font-style: Regular;
  text-align: right;
  font-family: Poppins;
  font-weight: 400;
  line-height: 28.799999237060547px;
  font-stretch: normal;
  text-decoration: none;
}
.header-text03 {
  top: 0.859375px;
  left: 335px;
  color: rgba(255, 255, 255, 1);
  width: 66px;
  height: auto;
  position: absolute;
  font-size: 16px;
  font-style: Regular;
  text-align: right;
  font-family: Poppins;
  font-weight: 400;
  line-height: 28.799999237060547px;
  font-stretch: normal;
  text-decoration: none;
}
.header-text05 {
  top: 0.859375px;
  left: 226px;
  color: rgba(255, 255, 255, 1);
  width: 79px;
  height: auto;
  position: absolute;
  font-size: 15px;
  font-style: Regular;
  text-align: right;
  font-family: Poppins;
  font-weight: 400;
  line-height: 28.799999237060547px;
  font-stretch: normal;
  text-decoration: none;
}
.header-text07 {
  top: 0.859375px;
  color: rgba(255, 255, 255, 1);
  width: 70px;
  height: auto;
  position: absolute;
  font-size: 15px;
  font-style: Regular;
  text-align: right;
  font-family: Poppins;
  font-weight: 400;
  line-height: 28.799999237060547px;
  font-stretch: normal;
  text-decoration: none;
}
.header-thq-dropdown {
  cursor: pointer;
  display: none;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.header-dropdown-toggle {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.header-text09 {
  font-size: 14px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-right: 4px;
  vertical-align: middle;
}
.header-dropdown-arrow {
  transition: 0.3s;
}
.header-icon {
  width: 18px;
  height: 18px;
  transition: 0.3s;
}
.header-dropdown-list {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-color: #D9D9D9;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-gray-white);
  list-style-position: inside;
}
.header-dropdown {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.header-dropdown-toggle1 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.header-dropdown-toggle1:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.header-text10 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.header-dropdown1 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.header-dropdown-toggle2 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.header-dropdown-toggle2:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.header-text11 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.header-dropdown2 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.header-dropdown-toggle3 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.header-dropdown-toggle3:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.header-text12 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.header-root-class-name {
  position: 0px;
  max-width: 1200px;
  align-self: stretch;
}
.header-root-class-name1 {
  position: 0px;
  max-width: 1200px;
  align-self: stretch;
  margin-top: var(--dl-space-space-twounits);
}
@media(max-width: 1200px) {
  .header-frame {
    gap: 0;
    width: 100%;
    justify-content: space-between;
  }
  .header-frame1 {
    align-self: center;
  }
  .header-group {
    position: 0px;
  }
}
@media(max-width: 991px) {
  .header-frame {
    width: 100%;
    padding: var(--dl-space-space-unit);
  }
}
@media(max-width: 767px) {
  .header-frame1 {
    display: none;
  }
  .header-thq-dropdown {
    display: inline-block;
  }
  .header-dropdown-list {
    display: none;
  }
}
</style>
