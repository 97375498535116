<template>
  <div class="button-white-frame" v-bind:class="rootClassName">
    <span class="button-white-text Button1">{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: 'ButtonWhite',
  props: {
    rootClassName: String,
    text: {
      type: String,
      default: 'Click Me!',
    },
  },
}
</script>

<style scoped>
.button-white-frame {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-fourunits);
  border-radius: var(--dl-radius-radius-radiusbutton);
  padding-right: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: #ffffff;
}
.button-white-text {
  color: rgb(0, 0, 0);
  width: auto;
  height: auto;
  align-self: center;
  text-align: center;
  font-stretch: normal;
}


.button-white-root-class-name2 {
  display: none;
  position: static;
  align-self: flex-start;
}

@media(max-width: 1200px) {
  .button-white-frame {
    position: static;
    flex-direction: row;
    justify-content: flex-start;
  }
  .button-white-text {
    position: static;
  }
}
@media(max-width: 479px) {
  .button-white-frame {
    width: 80%;
    justify-content: center;
  }
  .button-white-root-class-name {
    width: auto;
    position: 0px;
    align-self: stretch;
  }
  .button-white-root-class-name1 {
    width: auto;
    align-self: stretch;
  }
  .button-white-root-class-name2 {
    width: auto;
    align-self: stretch;
  }
  .button-white-root-class-name4 {
    width: auto;
    align-self: stretch;
  }
}
</style>
