<template>
  <div class="company-container">
    <div id="aboutus" class="company-about-us">
      <app-header rootClassName="header-root-class-name1"></app-header>
      <div class="company-frame">
        <img
          alt="IMAGE430"
          src="https://images.unsplash.com/photo-1704996440137-44a1eb3c71ee?ixid=M3w5MTMyMXwwfDF8YWxsfDJ8fHx8fHwyfHwxNzA1MDc5MDY3fA&amp;ixlib=rb-4.0.3&amp;w=1500"
          loading="lazy"
          class="company-image"
        />
        <span class="company-text">
          <span class="Heading2">About Us</span>
          <br class="Content1 company-text02" />
          <br class="Content1 company-text03" />
          <span class="Content1 company-text04">
            Astronod is at the forefront of innovation, empowering organizations
            to thrive in the digital age. With a dedication to excellence and a
            passion for pushing boundaries, we deliver tailored solutions that
            drive growth and maximize impact.
          </span>
          <br />
          <br />
          <span class="Heading2">Our Expertise</span>
          <br />
          <br />
          <span>
            Drawing from years of experience and expertise, we specialize in a
            wide range of services aimed at transforming industries and unlocking
            new possibilities. From custom software development to advanced data
            analytics, our solutions are designed to meet the diverse needs of our
            clients.
          </span>
          <br />
          <br />
          <span class="Heading2">EU Projects and Beyond</span>
          <br />
          <br />
          <span>
            While we have a strong track record of success in EU funded projects
            such as Erasmus+, Horizon2020, and more, our commitment extends beyond
            borders. We partner with businesses, governments, and organizations
            globally to deliver cutting-edge solutions that drive innovation and
            create positive change.
          </span>
          <br />
        </span>
      </div>
    </div>
    <div id="featured-work" class="company-image1">
      <div class="company-frame1">
        <img
          alt="image"
          src="https://images.unsplash.com/photo-1705923401054-b06daf310fc3?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8YWxsfDE0fHx8fHx8Mnx8MTcwNjA0NzM1Mnw&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=1200"
          class="company-image2"
        />
      </div>
    </div>
    <div id="nft-logo" class="company-nft-logo section">
      <span class="company-text18 Heading2">
        Unlock Your Unique Astronod Logo NFT
      </span>
      <div class="company-container1">
        <div class="company-container2">
          <div class="company-container3">
            <animate-on-reveal
              animation="none"
              duration="300ms"
              delay="0s"
              direction="normal"
              easing="ease"
              iteration="1"
            >
              <img
                alt="Vector4111"
                src="/external/vector4111-sftl.svg"
                data-thq-animate-on-reveal="true"
                class="company-vector"
              />
            </animate-on-reveal>
          </div>
          <div class="company-container4">
            <span class="company-text19 Content2">Astronod NFT:</span>
            <span class="company-text20 Content2">#OF98230585</span>
          </div>
        </div>
        <div class="company-container5">
          <span class="company-text21 Content1">
            <span>
              Astronod is thrilled to introduce an exclusive opportunity for our
              visitors to own a piece of digital innovation: the Astronod Logo
              NFT.
            </span>
            <br class="company-text23" />
            <br class="company-text24" />
            <span class="company-text25">
              As a pioneer in data-driven solutions, Astronod is excited to extend
              our innovative spirit to the world of NFTs. By owning an Astronod
              Logo NFT, you not only acquire a digital collectible but also become
              part of a community that celebrates creativity and innovation.
            </span>
            <br class="company-text26" />
          </span>
          <input
            type="text"
            required="true"
            placeholder="#OF98230585"
            class="company-textinput Content1 input"
          />
          <span class="company-text27 Content2">
            Enter a different code to generate another randomly generated
            parametric logo and discover endless possibilities!
          </span>
          <button-black
            text="Purchase your NFT"
            rootClassName="button-black-root-class-name3"
          ></button-black>
        </div>
      </div>
    </div>
    <div id="values" class="company-values">
      <h1 class="company-text28 Heading1">
        <span>Our values</span>
        <br />
      </h1>
      <div class="company-container6">
        <service-card rootClassName="rootClassName6">
          <svg viewBox="0 0 1024 1024" class="icon_service">
            <path
              d="M736 512q0-20-2-30l64-48q10-8 2-20l-60-104q-6-10-18-6l-74 30q-26-20-50-30l-12-78q-4-12-14-12h-120q-12 0-14 12l-12 80q-32 14-50 28l-74-30q-10-4-18 8l-60 102q-2 2-2 8 0 10 4 12l64 48q-2 10-2 30t2 30l-64 48q-4 2-4 12 0 6 2 8l60 104q6 10 18 6l74-30q26 20 50 30l12 78q4 12 14 12h120q12 0 14-12l12-80q32-14 50-28l74 30q10 4 18-8l60-102q8-12-2-20l-64-48q2-10 2-30zM810 128q36 0 61 25t25 61v596q0 36-25 61t-61 25h-596q-36 0-61-25t-25-61v-596q0-36 25-61t61-25h596zM512 426q34 0 60 26t26 60-26 60-60 26-60-26-26-60 26-60 60-26z"
            ></path>
          </svg>
        </service-card>
        <service-card
          title="Data Analysis"
          description="Uncover valuable insights from complex datasets, enabling informed decision-making and strategic planning"
          rootClassName="rootClassName7"
        >
          <svg viewBox="0 0 1024 1024" class="company-icon02 icon_service">
            <path
              d="M580 750q0-52 38-90t90-38 90 38 38 90-38 90-90 38-90-38-38-90zM342 256q0-52 38-90t90-38 90 38 38 90-38 90-90 38-90-38-38-90zM170 598q0-52 38-90t90-38 90 38 38 90-38 90-90 38-90-38-38-90z"
            ></path>
          </svg>
        </service-card>
        <service-card
          title="AI and Machine Learning Solutions"
          description="Implement sophisticated AI algorithms and machine learning models to automate processes and unlock new opportunities"
          rootClassName="rootClassName8"
        >
          <svg viewBox="0 0 1024 1024" class="icon_service">
            <path
              d="M616.149 181.12l-104.149 60.16-104.149-60.16 82.603-47.189c4.779-2.731 9.899-4.48 15.147-5.333 9.301-1.451 18.987 0.128 27.904 5.291zM170.667 585.941l106.667 61.611v120.747l-85.12-48.64c-4.736-2.773-8.832-6.315-12.16-10.411-5.931-7.381-9.387-16.512-9.387-26.581zM746.667 768.299v-120.747l106.667-61.611v96.725c-0.043 5.163-1.067 10.496-2.987 15.445-3.413 8.789-9.6 16.384-18.176 21.333zM809.003 291.328l-297.003 171.819-297.003-171.819 107.093-61.227 168.576 97.408c13.611 7.851 29.739 7.381 42.709 0l168.533-97.365zM491.776 979.669c6.016 3.243 12.928 5.077 20.224 5.077 7.381 0 14.336-1.877 20.395-5.163 15.189-2.475 29.909-7.68 43.392-15.36l136.448-77.995c9.301-1.835 17.536-6.699 23.637-13.483l138.624-79.232c26.368-15.232 45.269-38.315 55.424-64.597 5.675-14.592 8.619-30.165 8.747-46.251v-341.333c0-20.395-4.821-39.723-13.397-56.917-0.939-3.029-2.219-5.973-3.883-8.832-1.963-3.371-4.267-6.357-6.912-8.96-1.323-1.835-2.731-3.669-4.139-5.419-9.813-12.203-21.845-22.528-35.456-30.507l-139.008-79.403c-6.272-7.125-14.635-11.776-23.595-13.525l-136.405-77.952c-26.027-15.019-55.467-19.84-83.328-15.531-15.531 2.432-30.507 7.637-44.288 15.488l-136.533 77.995c-8.96 1.749-17.323 6.4-23.595 13.483l-138.624 79.232c-16.341 9.429-29.824 21.888-40.149 36.267-2.56 2.56-4.864 5.547-6.784 8.832-1.664 2.901-2.987 5.888-3.925 8.96-1.707 3.456-3.243 6.955-4.608 10.496-5.632 14.635-8.576 30.208-8.704 45.995v341.632c0.043 30.293 10.581 58.197 28.331 80.128 9.813 12.203 21.845 22.528 35.456 30.507l139.008 79.445c6.059 6.827 14.293 11.691 23.637 13.483l136.405 77.952c13.824 7.979 28.587 13.099 43.605 15.445zM469.333 537.045v340.949l-106.667-60.928v-194.133c0-15.701-8.491-29.44-21.333-36.949l-170.667-98.603v-123.093zM554.667 877.995v-340.949l298.667-172.757v123.093l-170.667 98.603c-13.611 7.851-21.248 22.059-21.333 36.949v194.133z"
            ></path>
          </svg>
        </service-card>
        <service-card
          title="UI/UX Design"
          description="Craft seamless and engaging user interfaces and experiences that captivate audiences and optimize usability across digital platforms"
          rootClassName="rootClassName9"
        >
          <svg viewBox="0 0 877.1291428571428 1024" class="icon_service">
            <path
              d="M804 472c0-26.857-5.143-97.143-14.286-120-10.857-27.429-17.143-42.857-17.143-73.714 0-26.286 5.714-52 5.714-77.714 0-10.857-0.571-22.286-5.714-31.429-2.286-0.571-5.143-0.571-7.429-0.571-22.286 0-44.571 5.143-66.857 5.143-68 0-132.571-27.429-200.571-27.429-53.143 0-104.571 20-153.714 39.429-38.857 15.429-81.714 33.714-115.429 58.857-115.429 87.429-155.429 247.429-155.429 385.143 0 46.286 14.286 91.429 14.286 137.714 0 26.286-12.571 50.286-12.571 75.429 0 16 9.143 29.143 26.286 29.143 28 0 54.857-12.571 83.429-12.571 65.143 0 128.571 17.714 193.714 17.714 50.857 0 114.857-4 162.286-20.571 150.286-53.143 263.429-226.857 263.429-384.571zM877.143 470.857c0 190.286-132 390.857-312.571 454.857-56.571 20-126.857 25.143-186.286 25.143-65.143 0-129.143-16.571-193.714-16.571-27.429 0-54.857 16.571-83.429 16.571-56.571 0-99.429-50.857-99.429-105.143 0-26.857 12.571-50.857 12.571-77.143 0-46.286-14.286-91.429-14.286-138.286 0-162.286 49.714-341.714 184.571-444 38.857-29.714 87.429-50.857 132.571-68.571 58.286-23.429 117.143-44.571 180.571-44.571 68 0 132.571 27.429 199.429 27.429 21.714 0 43.429-5.714 65.714-5.714 65.143 0 88.571 46.286 88.571 105.714 0 25.714-5.714 52-5.714 77.714 0 20.571 5.143 28.571 12 46.857 13.143 33.143 19.429 109.143 19.429 145.714z"
            ></path>
          </svg>
        </service-card>
        <service-card
          title="Satellite Data Utilization"
          description="Leverage satellite imagery and geospatial data to revolutionize industries such as agriculture, real estate, urban planning, and sustainability"
          rootClassName="rootClassName10"
        >
          <svg viewBox="0 0 967.4605714285714 1024" class="icon_service">
            <path
              d="M822.857 256c0-30.286-24.571-54.857-54.857-54.857s-54.857 24.571-54.857 54.857 24.571 54.857 54.857 54.857 54.857-24.571 54.857-54.857zM950.857 91.429c0 189.714-52.571 316-188 452-33.143 32.571-70.857 66.286-111.429 100.571l-11.429 216.571c-0.571 5.714-4 11.429-9.143 14.857l-219.429 128c-2.857 1.714-5.714 2.286-9.143 2.286-4.571 0-9.143-1.714-13.143-5.143l-36.571-36.571c-4.571-5.143-6.286-12-4.571-18.286l48.571-157.714-160.571-160.571-157.714 48.571c-1.714 0.571-3.429 0.571-5.143 0.571-4.571 0-9.714-1.714-13.143-5.143l-36.571-36.571c-5.714-6.286-6.857-15.429-2.857-22.286l128-219.429c3.429-5.143 9.143-8.571 14.857-9.143l216.571-11.429c34.286-40.571 68-78.286 100.571-111.429 142.857-142.286 252-188 450.857-188 10.286 0 19.429 8 19.429 18.286z"
            ></path>
          </svg>
        </service-card>
        <service-card
          title="Geospatial Analytics"
          description="Utilize advanced geospatial analysis techniques to extract meaningful insights from spatial data in areas such as infrastructure planning, disaster management, and environmental monitoring"
          rootClassName="rootClassName11"
        >
          <svg viewBox="0 0 1024 1024" class="icon_service">
            <path
              d="M512 810q124 0 211-87t87-211-87-211-211-87-211 87-87 211 87 211 211 87zM894 470h88v84h-88q-14 126-114 226t-226 114v88h-84v-88q-126-14-226-114t-114-226h-88v-84h88q14-126 114-226t226-114v-88h84v88q126 14 226 114t114 226zM512 342q70 0 120 50t50 120-50 120-120 50-120-50-50-120 50-120 120-50z"
            ></path>
          </svg>
        </service-card>
      </div>
    </div>
    <app-footer rootClassName="footer-root-class-name"></app-footer>
  </div>
</template>

<script>
import AppHeader from '../components/header'
import ButtonBlack from '../components/button-black'
import ServiceCard from '../components/service-card'
import AppFooter from '../components/footer'

export default {
  name: 'Company',
  props: {},
  components: {
    AppHeader,
    ButtonBlack,
    ServiceCard,
    AppFooter,
  },
  metaInfo: {
    title: 'AstroLab',
    meta: [
      {
        property: 'og:title',
        content: 'AstroLab',
      },
      {
        name: 'robots',
        content: 'noindex',
      },
    ],
  },
}
</script>

<style scoped>
.company-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #000000;
}
.company-about-us {
  width: 1200px;
  height: auto;
  display: flex;
  position: relative;
  align-self: center;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.company-frame {
  gap: var(--dl-space-space-twounits);
  width: 1200px;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: flex-start;
}
.company-image {
  width: 100%;
  height: 659px;
  position: static;
  align-self: flex-start;
}
.company-text {
  color: rgb(255, 255, 255);
  width: 511px;
  height: auto;
  position: static;
  text-align: left;
  font-stretch: normal;
}
.company-image1 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  height: auto;
  display: flex;
  max-width: 1200px;
  align-self: center;
  align-items: center;
  flex-shrink: 0;
  padding-top: var(--dl-space-space-eightunits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-eightunits);
  justify-content: center;
}
.company-frame1 {
  width: 100%;
  height: 1069px;
  display: flex;
  overflow: hidden;
  position: relative;
  max-width: 1200px;
  align-items: flex-start;
  flex-shrink: 0;
}
.company-image2 {
  top: -120px;
  flex: 1;
  left: 0px;
  width: 100%;
  position: absolute;
  align-self: center;
  object-fit: cover;
}
.company-nft-logo {
  flex-direction: column;
}
.company-text18 {
  color: rgba(0, 0, 0, 1);
  width: 100%;
  height: auto;
  max-width: 1200PX;
  text-align: center;
  font-stretch: normal;
}
.company-container1 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  max-width: 1200px;
  align-self: center;
  grid-template-columns: 1fr 1fr;
}
.company-container2 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  flex-direction: column;
  justify-content: space-between;
}
.company-container3 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.company-vector {
  width: 590px;
  height: 430px;
  align-self: center;
}
.company-container4 {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  flex-direction: row;
  justify-content: space-between;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.company-text19 {
  color: rgba(0, 0, 0, 1);
  width: 100%;
  height: auto;
  text-align: left;
  font-stretch: normal;
}
.company-text20 {
  color: rgba(0, 0, 0, 1);
  width: 100%;
  height: auto;
  text-align: right;
  font-stretch: normal;
}
.company-container5 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.company-text21 {
  color: rgba(0, 0, 0, 1);
  width: 100%;
  height: auto;
  text-align: center;
  font-stretch: normal;
}
.company-textinput {
  text-align: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radiusbutton);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.company-text27 {
  color: rgba(0, 0, 0, 1);
  width: 100%;
  height: auto;
  text-align: center;
  font-stretch: normal;
}
.company-values {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-self: center;
  align-items: center;
  padding-top: var(--dl-space-space-eightunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-eightunits);
}
.company-text28 {
  color: #ffffff;
  align-self: center;
}
.company-container6 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  align-self: center;
  grid-template-columns: 1fr 1fr 1fr;
}
.company-icon02 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
}
@media(max-width: 1200px) {
  .company-about-us {
    width: 100%;
    height: auto;
  }
  .company-frame {
    gap: var(--dl-space-space-twounits);
    width: 100%;
    height: auto;
    padding: var(--dl-space-space-twounits);
    position: static;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .company-image {
    flex: 1;
    left: 0px;
    right: 0px;
    width: 100%;
    height: auto;
    position: static;
    align-self: flex-start;
  }
  .company-text {
    position: static;
  }
  .company-text02 {
    position: static;
  }
  .company-text03 {
    position: static;
  }
  .company-text04 {
    position: static;
  }
  .company-image1 {
    width: 100%;
  }
  .company-text18 {
    color: rgb(0, 0, 0);
  }
  .company-container1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .company-container2 {
    width: auto;
    align-items: center;
    border-color: var(--dl-color-gray-black);
    border-width: 1px;
    justify-content: space-between;
  }
  .company-container3 {
    width: auto;
    height: auto;
    position: 0px;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  .company-vector {
    width: 488px;
    height: 390px;
    position: static;
    align-self: center;
  }
  .company-container4 {
    padding-top: var(--dl-space-space-halfunit);
    border-color: var(--dl-color-gray-black);
    border-width: 1px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-halfunit);
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
  }
  .company-container5 {
    gap: var(--dl-space-space-unit);
    align-items: center;
  }
  .company-text23 {
    color: #000000;
  }
  .company-text24 {
    color: #000000;
  }
  .company-text25 {
    color: #000000;
  }
  .company-text26 {
    color: #000000;
  }
  .company-textinput {
    text-align: center;
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-twounits);
    border-radius: var(--dl-radius-radius-radiusbutton);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
  }
  .company-text27 {
    color: rgb(0, 0, 0);
  }
}
@media(max-width: 991px) {
  .company-frame1 {
    height: 1029px;
  }
  .company-container1 {
    grid-template-columns: 1fr 1fr;
  }
  .company-container2 {
    justify-content: space-between;
  }
  .company-container3 {
    position: 0px;
    align-items: center;
    justify-content: flex-start;
  }
  .company-vector {
    top: 0px;
    left: 0px;
    width: 405px;
    height: 306px;
  }
  .company-text19 {
    text-align: left;
  }
  .company-text20 {
    text-align: right;
  }
  .company-container6 {
    grid-template-columns: 1fr 1fr;
  }
}
@media(max-width: 767px) {
  .company-about-us {
    width: 100%;
  }
  .company-frame {
    width: 100%;
  }
  .company-image {
    left: 0px;
    right: 0px;
    margin: auto;
  }
  .company-image1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .company-frame1 {
    height: 917px;
  }
  .company-container1 {
    grid-template-columns: 1fr;
  }
  .company-container2 {
    width: 100%;
    align-items: center;
  }
  .company-vector {
    width: 582px;
    height: 291px;
  }
  .company-values {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .company-text {
    width: 100%;
  }
  .company-text02 {
    width: 100%;
  }
  .company-text03 {
    width: 100%;
  }
  .company-text04 {
    width: 100%;
  }
  .company-frame1 {
    height: 461px;
  }
  .company-container1 {
    grid-template-columns: 1fr;
  }
  .company-vector {
    width: 355px;
    height: 235px;
  }
  .company-values {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .company-container6 {
    grid-template-columns: 1fr;
  }
}
</style>
